import { css } from '@emotion/react';
import { skeletonClasses } from '@mui/material/Skeleton';


export const globalStyles = css`
  :root {
    --thumbBG: #CDCDCD;
    --scrollbarBG: #F0F0F0;

    --avaleble-width: 100vw;
    --body-min-width: 1250px;
    @media (max-width: 1250px) {
      --avaleble-width: var(--body-min-width);
    }
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
      border-radius: 8px;
    }
  }

  body {
    min-width: var(--body-min-width);
  }

  #chat-widget-container {
    right: 22px !important;
    top: 50px !important;
    width: 480px !important;
    height: 800px !important;
  }

  .${skeletonClasses.root}.${skeletonClasses.rectangular} {
    border-radius: var(--theme-border-radius);
  }

  .SnackbarContainer-root.SnackbarContainer-root.SnackbarContainer-bottom {
    bottom: 98px;
  }

  .SnackbarItem-wrappedRoot .SnackbarContent-root {
    &.SnackbarItem-contentRoot {
      border-radius: var(--theme-border-radius);
      color: var(--theme-grey-900);
      border: 2px solid var(--theme-grey-300);
      padding: 4px 12px;
      font-weight: normal;
      font-size: 14px;

      svg.status-icon {
        margin-right: 10px;
      }
    }

    &.SnackbarItem-variantError {
      background-color: var(--theme-error-light);
      border-color: var(--theme-error-main);
    }

    &.SnackbarItem-variantWarning {
      background-color: var(--theme-warning-light);
      border-color: var(--theme-warning-main);
    }

    &.SnackbarItem-variantSuccess {
      background-color: var(--theme-success-light);
      border-color: var(--theme-success-main);
    }

    &.SnackbarItem-variantInfo {
      background-color: var(--theme-info-light);
      border-color: var(--theme-info-main);
    }
  }

  /*  BOT MESSAGES  */

  .messages-stack {
    --message-bubble-padding: 8px 10px;
    --message-bubble-radius: 8px;
    
    display: flex;
    flex-direction: column;

    padding-left: var(--theme-spacing-1half);
    width: 100%;

    align-items: flex-start;
    
    &.user-questions {
      align-items: end;
      padding-left: 25%;
    }

    & > :not(style)  {
      margin: 0;
    }
    
    & > :not(style) + :not(style) {
      margin-top: 4px;
    }
  }

  .message-content-bubble {
    padding: var(--message-bubble-padding);
    border-radius: var(--message-bubble-radius);
    background-color: var(--mb-bg);
    color: var(--mb-color);
    max-width: 330px;
    min-width: 60px;

    @media (max-width: 1390px) {
      max-width: 275px;
    }

    .preview & {
      background-color: var(--mb-bg-preview);
    }

    .edit-mode &,
    &.edit-mode {
      background-color: var(--mb-bg-editing);
      color: var(--mb-color-editing);
    }

    &.disabled {
      color: var(--mb-color-disable);

      .edit-mode &,
      &.edit-mode {
        color: var(--mb-color-disable-editing);
      }
    }
  }
`;
