import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setGetTokenWithPopup, setGetTokenSilently } from '@/api/api';


const BindTokenGetters = () => {
  const {
    getAccessTokenWithPopup,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    setGetTokenWithPopup(getAccessTokenWithPopup);
    setGetTokenSilently(getAccessTokenSilently);
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  return null;
};


export default BindTokenGetters;
