import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import Script from 'next/script';
import Head from 'next/head';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import pl from 'dayjs/locale/pl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextAdapter } from 'next-query-params';
import { QueryParamProvider } from 'use-query-params';
import { appWithTranslation } from 'next-i18next';
import log from 'loglevel';
import { CacheProvider, EmotionCache, Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import theme from '@/theme/theme';
import createEmotionCache from '@/theme/createEmotionCache';
import CustomSnackbarProvider from '@/components/CustomSnackbarProvider';
import { auth0ProviderConfig, googleTagManager, urlStructure } from '@/constants';
import { useIsServerSide, useDataLostSafetyWarning } from '@/hooks';
import { globalStyles } from '@/theme/globalStyles';
import BindTokenGetters from '@/components/BindTokenGetters';
import CssVariables from '@/components/CssVariables';
import App from '@/components/App';
import { pageView } from '@/utils/gtm';
import ErrorBoundary from '@/components/ErrorBoundary';


log.setDefaultLevel('error');
if (process.env.NODE_ENV === 'development') {
  log.setLevel('info', false);
}

dayjs.extend(isToday);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const localeMap: Record<string, object | undefined> = { en: undefined, pl };

interface CommonComponentsProps extends Pick<AppProps, 'router'> {
  emotionCache?: EmotionCache,
  children: React.ReactNode,
}

const CommonComponents = ({
  children,
  emotionCache = clientSideEmotionCache,
  router,
}: CommonComponentsProps) => {
  const isSeverSide = useIsServerSide();
  const uriBase = isSeverSide ? '' : window.location.origin;
  const redirectUri = uriBase + urlStructure.app;

  useEffect(() => {
    dayjs.locale(router.locale);
  }, [router.locale]);

  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: { queries: { staleTime: 300, retry: 3 } },
  }));

  // Google tag manager
  useEffect(() => {
    router.events.on('routeChangeComplete', pageView);
    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  useDataLostSafetyWarning();

  if (router?.locale === undefined) {
    return null;
  }

  const onRedirectCallback = (appState?: AppState) => {
    router.replace(appState?.returnTo ?? '/app', undefined, { shallow: false })
      .catch((e) => {
        log.debug(e);
      });
  };

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${googleTagManager}');
          `,
        }}
      />
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeMap[router.locale]}>
            <QueryParamProvider adapter={NextAdapter}>
              <Auth0Provider
                {...auth0ProviderConfig}
                redirectUri={redirectUri}
                onRedirectCallback={onRedirectCallback}
                cacheLocation="localstorage"
              >
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <CustomSnackbarProvider>
                    <BindTokenGetters />
                    <CssVariables />
                    <CssBaseline />
                    <Global
                      styles={globalStyles}
                    />
                    {children}
                  </CustomSnackbarProvider>
                </QueryClientProvider>
              </Auth0Provider>
            </QueryParamProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};


interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache,
}

const MyApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  router,
}: MyAppProps) => (
  <ErrorBoundary>
    <Head>
      <title>PerfectBot</title>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
    </Head>
    <CommonComponents
      emotionCache={emotionCache}
      router={router}
    >
      {router.asPath.startsWith('/app')
        ? (
          <App query={router.query}>
            <Component {...pageProps} />
          </App>
        )
        : <Component {...pageProps} />}
    </CommonComponents>
  </ErrorBoundary>
);


export default appWithTranslation(MyApp);
