export const CAN_USE_DOM: boolean = typeof window !== 'undefined'
  && typeof window.document !== 'undefined'
  && typeof window.document.createElement !== 'undefined';

export function shuffle<Type>(array: Type[]): Type[] {
  const newArray = [...array];
  let currentIndex = newArray.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [
      newArray[currentIndex],
      newArray[randomIndex],
    ] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ];
  }

  return newArray;
}

export function getRandomElement<Type>(array: Type[]): Type {
  return shuffle(array)[0];
}

export function customConfirm(message: string | null): boolean {
  // eslint-disable-next-line no-alert
  return window.confirm(message ?? undefined);
}
