import type PerfectBot from '@/types/PerfectBot';
import api from '@/api/api';


type GetCategories = null | {
  categoryId: string,
  categories: PerfectBot.Category[],
};

export async function getCategories(instanceId: string): Promise<PerfectBot.Category[] | null> {
  if (instanceId === '') {
    return null;
  }

  // szkielet
  const response = await api.get(`instances/${instanceId}/categories`);
  const data: GetCategories = await response.json();
  return data?.categories ?? [];
}

export async function getInstance(
  instanceId: string | undefined,
): Promise<PerfectBot.Instance | null> {
  if (!instanceId) {
    return null;
  }

  const response = await api.get(`instances/${instanceId}`);
  const data: PerfectBot.Instance = await response.json();
  return data;
}
