import React from 'react';
import type { InstanceContextState } from './types';


const InstanceContext = React.createContext<InstanceContextState>({
  instance: null,
  instanceId: '',
  changeInstance: () => null,
  isLoadingInstance: true,
});


export default InstanceContext;
