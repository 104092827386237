import type { PaletteOptions } from '@mui/material/styles/createPalette';


export const colors = {
  primary: '#002967', // dark-blue
  purpleLight: '#FAF6FC',
  purple: '#7400AC',
  darkPurple: '#331F9A',
  messageBubblePurple: '#703c8a',
  messageBubbleBlue: '#3F3185',
  livechatOrange: '#FE5000',
  gold: '#FFCD38',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#A5A5A5', // light-grey
    600: '#757575',
    700: '#616161',
    800: '#424242',
    850: '#333333',
    900: '#282828', // grey
  },
  blue: {
    25: '#F6F9FC',
    50: '#EAF1F8', // light-blue
    75: '#DCE6F1', // light-blue-border
    100: '#c8d8ef',
    200: '#a9c1e3',
    300: '#8baad9',
    400: '#7898d6',
    500: '#6a88d2',
    600: '#647bc4',
    700: '#5b69b1',
    800: '#52599f',
    900: '#433c80',
  },
};

export default function themePalette(): PaletteOptions {
  return {
    mode: 'light',
    common: {
      black: '#111936',
      accent: colors.purple,
      purple: colors.purple,
      purpleLight: colors.purpleLight,
      messageBubblePurple: colors.messageBubblePurple,
      messageBubbleBlue: colors.messageBubbleBlue,
      livechatOrange: colors.livechatOrange,
      gold: '#FFCD38',
    },
    primary: {
      light: '#4F6A92',
      main: colors.primary,
    },
    secondary: {
      main: '#00A573',
    },
    orange: {
      light: '#fbe9e7',
      main: '#ffab91',
      dark: '#d84315',
    },
    error: {
      light: '#FFF1F0',
      main: '#F44336',
    },
    warning: {
      light: '#FFF8E2',
      main: '#FFC107',
    },
    info: {
      light: '#f6faff',
      main: '#155bc5',
    },
    success: {
      light: '#EFFFF0',
      main: '#4CAF50',
    },
    grey: colors.grey,
    blue: colors.blue,
    text: {
      primary: colors.primary,
      secondary: colors.grey[500],
      black: colors.grey[900],
      white: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
      background: colors.blue[50],
    },
    action: {
      hover: 'rgba(25, 100, 170, 0.04)',
      selected: colors.blue[50],
    },
    tagsColors: {
      default: { backgroundColor: '#F9F9F9', labelColor: '#B9B9B9' },
      link: { backgroundColor: '#E2E0ED', labelColor: colors.messageBubbleBlue },
      handover: { backgroundColor: '#FCEFD2', labelColor: '#FF6600' },
      'handover-candidate': { backgroundColor: '#F9F9F9', labelColor: '#B9B9B9' },
      webhook: { backgroundColor: '#DEEFFD', labelColor: '#2196F3' },
      'webhook-candidate': { backgroundColor: '#F9F9F9', labelColor: '#B9B9B9' },
    },
  };
}
