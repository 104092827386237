export interface NewIntentDescriptionData {
  name: string,
  userFullName: string,
  email: string,
  organization: string,

  title: string,
  question: string,
  response: string,
  comments: string,
  priority: string,
}

export function createNewIntentDescription(data: NewIntentDescriptionData) {
  return `
${data.userFullName} (${data.organization})
${data.email}

---

Priority: \`${data.priority}\` 

Title:
\`\`\`
${data.title}
\`\`\`

User question examples:
\`\`\`
${data.question}
\`\`\`

PerfectBot response:
\`\`\`
${data.response}
\`\`\`

Comments:
\`\`\`
${data.comments}
\`\`\`
`;
}

export interface TrialRequestData {
  email: string,
  website: string,
}

export function createTrialRequestDescription(data: TrialRequestData) {
  return `
E-mail: \`${data.email}\`
URL: \`${data.website}\`

Privacy Policy (https://perfectbot.ai/legal/privacy-policy/) - accepted
Terms of Service (https://perfectbot.ai/legal/terms-of-service/) - accepted
`;
}

export interface FullPlanRequestData {
  name: string,
  userFullName: string,
  organization: string,
  email: string,
  offer: string,
}


export function createFullPlanRequestDescription(data: FullPlanRequestData) {
  return `
${data.userFullName} (${data.organization})
${data.email}

---

Selected offer: \`${data.offer}\` 
`;
}
