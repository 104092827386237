function getDataLayer(): GoogleTagManager_.Interface {
  // @ts-ignore
  return (window.dataLayer ?? []) as GoogleTagManager_.Interface;
}

export function pageView(url: string) {
  getDataLayer().push({
    event: 'pageview',
    page: url,
  });
}

export function customGtmEvent(
  eventName: string,
  payload: Record<string, string | number | null> = {}
) {
  getDataLayer().push({
    event: eventName,
    ...payload,
  });
}
