import React, { useCallback, useMemo, useState } from 'react';
import { useInstanceContext } from '@/contexts/InstanceContext';
import type { BotContextState } from '../types';
import GorgiasWidget from './GorgiasWidget';


export const GorgiasBotContext = React.createContext<BotContextState>({
  isBotAvailable: true,
  botStatus: 'loading',
  toggleChatWindow: () => null,
  isLoadingBotContext: true,
  sendMessage: () => Promise.resolve(),
});


interface GorgiasBotContextProviderProps {
  children: React.ReactNode,
}

const GorgiasBotContextProvider = ({ children }: GorgiasBotContextProviderProps) => {
  const { instance } = useInstanceContext();
  const [botStatus, setBotStatus] = useState<BotContextState['botStatus']>('loading');

  const toggleChatWindow = useCallback(() => {
    if (window.GorgiasChat && botStatus !== 'loading') {
      void window.GorgiasChat.init().then(() => {
        const widget = window.GorgiasChat as GorgiasWidget_.Widget;
        if (widget.isOpen()) {
          widget.close();
          setBotStatus('closed');
        } else {
          widget.open();
          setBotStatus('open');
        }
      });
    }
  }, [botStatus]);

  const sendMessage = useCallback((message: string) => new Promise<void>((resolve) => {
    if (window.GorgiasChat && botStatus !== 'loading') {
      void window.GorgiasChat.init().then(() => {
        const widget = window.GorgiasChat as GorgiasWidget_.Widget;
        if (!widget.isOpen()) {
          toggleChatWindow();
        }
        widget.sendMessage(message);
      });
    }
    resolve();
  }), [botStatus, toggleChatWindow]);

  const isLoadingBotContext = !instance;
  const data = useMemo(() => ({
    isBotAvailable: true,
    botStatus,
    toggleChatWindow,
    isLoadingBotContext,
    sendMessage,
  }), [botStatus, toggleChatWindow, isLoadingBotContext, sendMessage]);

  return (
    <GorgiasBotContext.Provider value={data}>
      {children}
      <GorgiasWidget setBotStatus={setBotStatus} />
    </GorgiasBotContext.Provider>
  );
};

export default GorgiasBotContextProvider;
