export const couldNotGetChatDataErrorMessage = 'Could not get chat_data.';

// eslint-disable-next-line max-len
export const getChatData = (widget: LiveChat_.Widget) => new Promise<LiveChat_.ChatData>((resolve, reject) => {
  let chatData = widget.get('chat_data') as Partial<LiveChat_.ChatData>;

  if (chatData.chatId && chatData.threadId) {
    resolve(chatData as LiveChat_.ChatData);
  } else {
    let tryOut = 15;

    const interval = setInterval(() => {
      chatData = widget.get('chat_data') as Partial<LiveChat_.ChatData>;
      if (chatData.chatId && chatData.threadId) {
        clearInterval(interval);
        resolve(chatData as LiveChat_.ChatData);
      } else {
        tryOut -= 1;
        if (tryOut < 0) {
          clearInterval(interval);
          reject(new Error(couldNotGetChatDataErrorMessage));
        }
      }
    }, 100);
  }
});
