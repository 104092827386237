const app = '/app';

const urlStructure = {
  login: '/login',
  welcome: '/welcome',
  help: '/help',
  noPermissions: '/no-permissions',
  error: '/error',
  app,
  appInstance: (instanceId: string = '[instanceId]') => `${app}/${instanceId}`,
  intentsInstance: (instanceId: string = '[instanceId]') => `${app}/${instanceId}/intents`,
};

export const homeAddress = (instanceId: string = '[instanceId]') => urlStructure.appInstance(instanceId);

export default urlStructure;
