import api from '@/api/api';
import {
  createFullPlanRequestDescription,
  createNewIntentDescription,
  createTrialRequestDescription,
  FullPlanRequestData,
  NewIntentDescriptionData,
  TrialRequestData,
} from '@/utils/clickupDescriptions';


export async function requestNewIntent(instanceId: string, data: NewIntentDescriptionData) {
  await api.post(`instances/${instanceId}/integrations/clickup/tasks`, {
    json: {
      name: data.name,
      description: createNewIntentDescription(data),
    },
  });
}

export async function requestTrial(instanceId: string, data: TrialRequestData) {
  await api.post(`instances/${instanceId}/integrations/clickup/tasks`, {
    json: {
      name: 'SANDBOX | New trial request',
      description: createTrialRequestDescription(data),
    },
  });
}

export async function requestFullPlan(instanceId: string, data: FullPlanRequestData) {
  await api.post(`instances/${instanceId}/integrations/clickup/tasks`, {
    json: {
      name: data.name,
      description: createFullPlanRequestDescription(data),
    },
  });
}

export async function testBotResponse(
  instanceId: string,
  chatData: LiveChat_.ChatData,
  text: string,
) {
  await api.post(`instances/${instanceId}/integrations/livechat/${chatData.chatId}/${chatData.threadId}/message`, {
    json: {
      text,
    },
  });
}
