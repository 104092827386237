import type PerfectBot from '@/types/PerfectBot';
import api from '@/api/api';


type GetInstances = null | {
  userId: string,
  instances: PerfectBot.Instance[],
};

export async function getInstances(
  userId: string | undefined
): Promise<PerfectBot.Instance[] | null> {
  if (!userId) {
    return null;
  }
  const response = await api.get(`users/${userId}/instances`);
  const data: GetInstances = await response.json();
  return data?.instances ?? null;
}

export async function getUser(userId: string | undefined): Promise<Omit<PerfectBot.User, 'fullName'> | null> {
  if (!userId) {
    return null;
  }
  const response = await api.get(`users/${userId}`);
  const data: Omit<PerfectBot.User, 'fullName'> = await response.json();
  return data;
}
