/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { createTheme } from '@mui/material/styles';
import type { Theme, ThemeOptions } from '@mui/material/styles/createTheme';
import themeMixins from './themeMixins';
import themeTypography from './themeTypography';
import themePalette from './themePalette';
import componentStyleOverrides from './componentStyleOverrides';


const getTheme = () => {
  const themeOptions: ThemeOptions = {
    palette: themePalette(),
    mixins: themeMixins(),
    shape: {
      borderRadius: 5,
    },
    typography: themeTypography(),
    zIndex: {
      modal: 2147483641,
      snackbar: 2147483643,
      tooltip: 2147483645,
    },
    spacing: 6,
    components: componentStyleOverrides(),
  };

  const themes: Theme = createTheme(themeOptions);

  return themes;
};

const theme = getTheme();
export default theme;
