import React, { useContext } from 'react';
import { useInstanceContext } from '@/contexts/InstanceContext';
import LivechatBotContextProvider, { LivechatBotContext } from './LivechatBotContext';
import NoBotContextProvider, { NoBotContext } from './NoBotContext';
import GorgiasBotContextProvider, { GorgiasBotContext } from './GorgiasBotContext';
import { BotContextState } from './types';


// eslint-disable-next-line max-len
export const useBotContext = (): BotContextState => {
  const { instance } = useInstanceContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let context: React.Context<BotContextState> = NoBotContext;

  if (instance?.widget?.allowAccess) {
    if (instance?.widget?.platform === 'livechat') {
      context = LivechatBotContext;
    }
    if (instance?.widget?.platform === 'gorgias') {
      context = GorgiasBotContext;
    }
  }
  // @ts-ignore
  return useContext(context);
};


interface BotContextProviderProps {
  children: React.ReactNode,
}

const Index = (props: BotContextProviderProps) => {
  const { instance } = useInstanceContext();

  if (instance?.widget?.allowAccess) {
    if (instance?.widget?.platform === 'livechat') {
      return <LivechatBotContextProvider {...props} />;
    }
    if (instance?.widget?.platform === 'gorgias') {
      return <GorgiasBotContextProvider {...props} />;
    }
  }
  return <NoBotContextProvider {...props} />;
};


export default Index;
