import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import log from 'loglevel';
import { HTTPError } from 'ky';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import LivechatWidget from '@/contexts/BotContext/LivechatBotContext/LivechatWidget';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { testBotResponse } from '@/api/instances/integrations';
import type { BotContextState } from '../types';
import { couldNotGetChatDataErrorMessage, getChatData } from './getChatData';


export const LivechatBotContext = React.createContext<BotContextState>({
  isBotAvailable: true,
  botStatus: 'loading',
  toggleChatWindow: () => null,
  isLoadingBotContext: true,
  sendMessage: () => Promise.resolve(),
});


interface LivechatBotContextProviderProps {
  children: React.ReactNode,
}


const LivechatBotContextProvider = ({ children }: LivechatBotContextProviderProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { instanceId, instance } = useInstanceContext();
  const widgetRef = useRef<LiveChat_.Widget>();

  const [botStatus, setBotStatus] = useState<BotContextState['botStatus']>('loading');


  useEffect(() => {
    widgetRef.current = window.LiveChatWidget;
  }, [botStatus]);

  const toggleChatWindow = useCallback(() => {
    if (
      botStatus !== 'loading'
      && widgetRef.current
    ) {
      const widget: LiveChat_.Widget = widgetRef.current;
      const livechatState = widget.get('state') as LiveChat_.State;
      if (livechatState.visibility === 'maximized') {
        widget.call('hide');
        setBotStatus('closed');
      } else {
        widget.call('maximize');
        setBotStatus('open');
      }
    }
  }, [botStatus]);


  const sendMessage = useCallback(async (message: string) => {
    if (botStatus !== 'loading' && widgetRef.current) {
      try {
        const chatData = await getChatData(widgetRef.current);

        await testBotResponse(
          instanceId,
          chatData,
          message,
        );
      } catch (error) {
        log.error(error);

        if ((error as Error).message === couldNotGetChatDataErrorMessage) {
          enqueueSnackbar(t('error:errorTheChatHasNotYetBeenStarted'), { variant: 'error' });
        } else if ((error as HTTPError)?.response?.status === 406) {
          enqueueSnackbar(t('error:errorTheChatHasBeenClosedPleaseRestart'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('error:errorSendingMessageToLivechat'), { variant: 'error' });
        }
      }
    }
  }, [botStatus, enqueueSnackbar, instanceId, t]);


  const isLoadingBotContext = !instance;
  const data = useMemo(() => ({
    isLoadingBotContext,
    isBotAvailable: true,
    botStatus,
    toggleChatWindow,
    sendMessage,
  }), [botStatus, isLoadingBotContext, toggleChatWindow, sendMessage]);


  return (
    <LivechatBotContext.Provider value={data}>
      {children}
      <LivechatWidget setBotStatus={setBotStatus} />
    </LivechatBotContext.Provider>
  );
};

export default LivechatBotContextProvider;
