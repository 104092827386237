/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import type PerfectBot from '@/types/PerfectBot';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { initChat } from './initChat.js';


interface LivechatWidgetProps {
  setBotStatus: (newValue: 'loading' | 'open' | 'closed') => void,
}

const LivechatWidget = ({
  setBotStatus,
}: LivechatWidgetProps) => {
  const { instance, isLoadingInstance } = useInstanceContext();
  const { livechatLicenseId, livechatGroupId } = instance?.widget as PerfectBot.LiveChatWidget;

  useEffect(() => {
    if (!isLoadingInstance) {
      window.__lc = {
        license: livechatLicenseId,
        group: livechatGroupId ?? '0',
        chat_between_groups: false,
      };

      setBotStatus('loading');
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const widget = initChat(window, document, [].slice, 'livechat-head-script');
      widget.call('hide');

      widget.on('ready', () => {
        setBotStatus('closed');
        widget.call('hide');
      });

      widget.on('greeting_displayed', () => {
        widget.call('hide_greeting');
      });

      widget.on('visibility_changed', (data) => {
        if (data.visibility === 'minimized') {
          widget.call('hide');
          setBotStatus('closed');
        }
      });

      return () => {
        widget.call('destroy');
        setBotStatus('loading');
        const script = document.getElementById('livechat-head-script');
        if (script) {
          script.remove();
        }
      };
    }
    return () => {};
  }, [
    livechatLicenseId,
    livechatGroupId,
    isLoadingInstance,
    setBotStatus,
  ]);

  return null;
};


export default LivechatWidget;
