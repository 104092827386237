import { useMemo } from 'react';
import { useRouter } from 'next/router';
import log from 'loglevel';
import type { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import type PerfectBot from '@/types/PerfectBot';
import { waitAndGet } from '@/utils/waitAndGet';
import { getUser } from '@/api/users';
import { urlStructure } from '@/constants';
import useOnError from './useOnError';


function useUserData() {
  const { t } = useTranslation();
  const { push } = useRouter();
  const onError = useOnError();
  const { user } = useAuth0();

  const {
    data: userData,
    isLoading: isLoadingUserData,
  } = useQuery<Omit<PerfectBot.User, 'fullName'> | null, HTTPError>(
    ['getUser', user?.sub],
    () => waitAndGet(() => getUser(user?.sub), user?.sub),
    {
      onError: (error) => {
        if (error?.response?.status === 403) {
          push({
            pathname: urlStructure.error,
            query: { errorMessage: 'e001', logoutButton: 'true' },
          }).catch((e) => log.debug(e));
        } else {
          onError(error, t('error:errorFetchingUser'), 'warning');
        }
      },
      cacheTime: 60 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );

  const fullName = [userData?.firstName, userData?.familyName].join(' ');

  return useMemo(() => ({
    userData: userData
      ? {
        ...userData,
        fullName,
      } : undefined,
    isLoadingUserData,
    authData: user,
  }), [isLoadingUserData, user, userData, fullName]);
}

export default useUserData;
