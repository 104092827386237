import type { TypographyOptions } from '@mui/material/styles/createTypography';
import { colors } from './themePalette';


export default function themeTypography(): TypographyOptions {
  return {
    fontFamily: '\'Mulish\', sans-serif',
    h6: {
      fontWeight: 600,
      fontSize: '0.75rem',
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h1: {
      fontSize: '1.875rem',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '0.9375rem',
      fontWeight: 600,
      color: colors.grey[900],
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.15em',
      color: colors.grey[500],
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: colors.grey[900],
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.334em',
    },
    body2: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'capitalize',
    },
  };
}
