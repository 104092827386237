import React from 'react';
import { Router } from 'next/router';
import CategoriesContextProvider from '@/contexts/CategoriesContext';
import Intercom from '@/components/Intercom';
import { InstanceContextProvider } from '@/contexts/InstanceContext';
import BotContextProvider from '@/contexts/BotContext';
import UrlTableParamsContextProvider from '@/contexts/UrlTableParamsContext';
import UnauthorisedRedirect from '@/components/auth/UnauthorisedRedirect';


const disableIntercom = process.env.TESTS_MODE;

export interface AppProps {
  children: React.ReactNode,
  query: Router['query'],
}

const App = ({ children, query }: AppProps) => (
  <>
    <UnauthorisedRedirect />
    <InstanceContextProvider instanceId={query.instanceId as string | undefined}>
      <UrlTableParamsContextProvider>
        <CategoriesContextProvider>
          <BotContextProvider>
            {children}
            {!disableIntercom && <Intercom />}
          </BotContextProvider>
        </CategoriesContextProvider>
      </UrlTableParamsContextProvider>
    </InstanceContextProvider>
  </>
);


export default App;
