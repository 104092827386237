import type { MixinsOptions } from '@mui/material/styles/createMixins';


export default function themeMixins(): MixinsOptions {
  return {
    appLayout: {
      headerHeight: '74px',
      sidebarWidth: '262px',
    },
    dialog: {
      standardWidth: '500px',
    },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: '8px',
    },
  };
}
