import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export interface LoaderProps extends CircularProgressProps {
  centered?: boolean,
}

export const Loader = ({ centered = false, ...otherProps }: LoaderProps) => (
  <Box
    sx={centered ? {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    } : {}}
  >
    <CircularProgress color="primary" {...otherProps} />
  </Box>
);

export default Loader;
