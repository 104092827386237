import React, { useRef } from 'react';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import IconButton from '@mui/material/IconButton';
import { IconX } from '@tabler/icons';
import IconStatusSuccess from '@/svg/icons/status_success_24.svg';
import IconStatusError from '@/svg/icons/status_error_24.svg';
import IconStatusWarning from '@/svg/icons/status_warning_24.svg';
import IconStatusInfo from '@/svg/icons/status_info_24.svg';
import { notistackOptions } from '@/constants';


const iconSize = 24;
const CustomSnackbarProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const notistackRef = useRef<SnackbarProvider>(null);
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  const dismissAction = (key: SnackbarKey) => (
    <IconButton size="small" aria-label="close" color="inherit" onClick={onClickDismiss(key)}>
      <IconX size="20px" />
    </IconButton>
  );

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={dismissAction}
      iconVariant={{
        success: <IconStatusSuccess className="status-icon" height={iconSize} width={iconSize} />,
        error: <IconStatusError className="status-icon" height={iconSize} width={iconSize} />,
        warning: <IconStatusWarning className="status-icon" height={iconSize} width={iconSize} />,
        info: <IconStatusInfo className="status-icon" height={iconSize} width={iconSize} />,
      }}
      {...notistackOptions}
    >
      {children}
    </SnackbarProvider>
  );
};


export default CustomSnackbarProvider;
