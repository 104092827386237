import React from 'react';
import Head from 'next/head';
import { useTheme } from '@mui/material/styles';


const CssVariables = () => {
  const theme = useTheme();
  // noinspection HtmlRequiredTitleElement
  return (
    <Head>
      <style id="css-variables">
        {`
          :root {
            --theme-border-radius: ${theme.shape.borderRadius}px;
            
            --theme-spacing-half: ${theme.spacing(0.5)};
            --theme-spacing-1: ${theme.spacing(1)};
            --theme-spacing-1half: ${theme.spacing(1.5)};
            --theme-spacing-2: ${theme.spacing(2)};
            --theme-spacing-2half: ${theme.spacing(2.5)};
            --theme-spacing-3: ${theme.spacing(3)};
            --theme-spacing-3half: ${theme.spacing(3.5)};
            --theme-spacing-4: ${theme.spacing(4)};
            --theme-spacing-4half: ${theme.spacing(4.5)};
            --theme-spacing-5: ${theme.spacing(5)};
            --theme-spacing-6: ${theme.spacing(6)};
            --theme-spacing-7: ${theme.spacing(7)};
            --theme-spacing-8: ${theme.spacing(8)};
            --theme-spacing-9: ${theme.spacing(9)};
            --theme-spacing-10: ${theme.spacing(10)};
            --theme-spacing-11: ${theme.spacing(11)};
            --theme-spacing-12: ${theme.spacing(12)};
            
            --theme-error-main: ${theme.palette.error.main};
            --theme-error-light: ${theme.palette.error.light};
            --theme-error-dark: ${theme.palette.error.dark};
            --theme-warning-main: ${theme.palette.warning.main};
            --theme-warning-light: ${theme.palette.warning.light};
            --theme-warning-dark: ${theme.palette.warning.dark};
            --theme-info-main: ${theme.palette.info.main};
            --theme-info-light: ${theme.palette.info.light};
            --theme-info-dark: ${theme.palette.info.dark};
            --theme-success-main: ${theme.palette.success.main};
            --theme-success-light: ${theme.palette.success.light};
            --theme-success-dark: ${theme.palette.success.dark};
            
            --theme-grey-50: ${theme.palette.grey[50]};
            --theme-grey-100: ${theme.palette.grey[100]};
            --theme-grey-200: ${theme.palette.grey[200]};
            --theme-grey-300: ${theme.palette.grey[300]};
            --theme-grey-400: ${theme.palette.grey[400]};
            --theme-grey-500: ${theme.palette.grey[500]};
            --theme-grey-600: ${theme.palette.grey[600]};
            --theme-grey-700: ${theme.palette.grey[700]};
            --theme-grey-800: ${theme.palette.grey[800]};
            --theme-grey-900: ${theme.palette.grey[900]};
            
            --mb-bg: ${theme.palette.background.paper};
            --mb-bg-preview: ${theme.palette.blue[50]};
            --mb-bg-editing: ${theme.palette.common.messageBubbleBlue};
            
            --mb-color: ${theme.palette.text.black};
            --mb-color-editing: ${theme.palette.text.white};
            --mb-color-disable: ${theme.palette.text.black};
            --mb-color-disable-editing: ${theme.palette.text.white};
          }
        `}
      </style>
    </Head>
  );
};


export default CssVariables;
