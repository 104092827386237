import React, {
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { SortingRule } from '@/types/PerfectBot';


export enum SortOptions {
  POPULARITY = 'popularity',
  INTENT_STATUS_ID = 'intentStatusId',
  INTENT_NAME = 'intentName',
}

interface UrlTableParamsContextState {
  selectedIntentId: string | null,
  setSelectedIntentId: (id: string | null) => void,
  currentPage: number,
  setCurrentPage: (pageNumber: number) => void,
  filter: string,
  setFilter: (filter: string) => void,
  sortBy: SortingRule | null,
  setSortBy: (newSortField: SortOptions) => void,
}

export const UrlTableParamsContext = React.createContext<UrlTableParamsContextState>({
  selectedIntentId: null,
  setSelectedIntentId: () => null,
  currentPage: 0,
  setCurrentPage: () => null,
  filter: 'all',
  setFilter: () => null,
  sortBy: null,
  setSortBy: () => null,
});


export const useUrlTableParamsContext = () => useContext(UrlTableParamsContext);

interface UrlTableParamsContextProviderProps {
  children: React.ReactNode,
}

const UrlTableParamsContextProvider = ({ children }: UrlTableParamsContextProviderProps) => {
  const [tableParams, setTableParams] = useQueryParams({
    'selected-intent': StringParam,
    page: NumberParam,
    filter: StringParam,
    sortBy: StringParam,
    sortDirection: StringParam,
  });

  const handleSelectedIntentIdChange = useCallback((newId: string | null) => {
    setTableParams({ 'selected-intent': newId ?? undefined });
  }, [setTableParams]);

  const handleCurrentPageChange = useCallback((newPage: number) => {
    setTableParams({ page: newPage });
  }, [setTableParams]);

  const handleFilterChange = useCallback((newFilter: string) => {
    setTableParams({
      filter: newFilter,
      page: undefined,
    });
  }, [setTableParams]);


  const sortBy = useMemo(() => {
    const field = tableParams.sortBy ?? SortOptions.POPULARITY;
    let isDirectionDescending: boolean;

    if (tableParams.sortDirection) {
      isDirectionDescending = tableParams.sortDirection === 'desc';
    } else {
      isDirectionDescending = field === SortOptions.POPULARITY;
    }

    return ({
      f: field,
      d: isDirectionDescending,
    });
  }, [tableParams.sortBy, tableParams.sortDirection]);

  const setSortBy = useCallback((newSortBy: SortingRule) => {
    setTableParams({
      sortBy: newSortBy.f,
      sortDirection: newSortBy.d ? 'desc' : 'asc',
      page: undefined,
    });
  }, [setTableParams]);

  const handleSortByChange = useCallback((newSortField: SortOptions) => {
    if (sortBy === null || sortBy?.f !== newSortField) {
      setSortBy({ f: newSortField, d: newSortField === 'popularity' });
    } else {
      setSortBy({ f: newSortField, d: !sortBy.d });
    }
  }, [setSortBy, sortBy]);

  const data = useMemo(() => ({
    selectedIntentId: tableParams['selected-intent'] ?? null,
    setSelectedIntentId: handleSelectedIntentIdChange,
    currentPage: tableParams.page ?? 0,
    setCurrentPage: handleCurrentPageChange,
    filter: tableParams.filter ?? 'all',
    setFilter: handleFilterChange,
    sortBy,
    setSortBy: handleSortByChange,
  }), [
    tableParams,
    handleCurrentPageChange,
    handleFilterChange,
    handleSelectedIntentIdChange,
    sortBy,
    handleSortByChange,
  ]);

  return (
    <UrlTableParamsContext.Provider value={data}>
      {children}
    </UrlTableParamsContext.Provider>
  );
};

export default UrlTableParamsContextProvider;
