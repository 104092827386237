import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { loadIntercom } from 'next-intercom';
import log from 'loglevel';
import { useAuth0 } from '@auth0/auth0-react';
import { intercomAppId } from '@/constants';
import { useUserData, useInstanceType } from '@/hooks';
import { verifiedIntercomUser } from '@/api/integrations';


const Intercom = () => {
  const { pathname, query } = useRouter();
  const { isLoading: isLoadingAuth0 } = useAuth0();
  const {
    authData,
    userData,
    isLoadingUserData,
  } = useUserData();

  useEffect(() => {
    if (
      window.Intercom
      && typeof window.Intercom === 'function'
      && !isLoadingAuth0
      && !isLoadingUserData
      && authData?.email != null
    ) {
      verifiedIntercomUser()
        .then((hmac) => {
          window.Intercom('update', {
            email: authData?.email,
            Organization: userData?.organization?.fullName,
            user_hash: hmac,
          });
        })
        .catch((e) => log.error(e));
    }
  }, [authData?.email, isLoadingAuth0, isLoadingUserData, userData?.organization?.fullName]);

  useEffect(() => {
    if (window.Intercom === undefined) {
      void loadIntercom({
        app_id: intercomAppId,
        ssr: false,
        initWindow: true,
        delay: 0,
      });
    }
  }, []);


  const instanceType = useInstanceType();
  useEffect(() => {
    if (
      window.Intercom
      && typeof window.Intercom === 'function'
      && !isLoadingAuth0
    ) {
      if (
        pathname === '/signup'
        && typeof query.website === 'string'
        && query.website !== ''
      ) {
        window.Intercom('update', {
          Organization: query.website,
        });
      } else if (
        !isLoadingUserData
        && authData?.email != null
      ) {
        window.Intercom('update', {
          name: userData?.fullName,
          email: authData?.email,
          Organization: userData?.organization?.fullName,
        });
      }
    }
  }, [
    isLoadingUserData,
    isLoadingAuth0,
    authData?.email,
    instanceType,
    userData?.fullName,
    userData?.organization?.fullName,
    pathname,
    query.website,
  ]);

  return null;
};


export default React.memo(Intercom);
