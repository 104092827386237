import * as process from 'process';


export const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string;
export const auth0ClientID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string;

export const auth0ProviderConfig = {
  domain: auth0Domain,
  clientId: auth0ClientID,
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string,
  scope: 'openid profile email',
};

export const apiUrl = process.env.NEXT_PUBLIC_API_URL as string;

export const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string;

export const googleTagManager = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string;

export const textMessageLengthSoftLimit = 150;
export const textMessageLengthHardLimit = 500;

export const privacyPolicy = 'https://perfectbot.ai/legal/privacy-policy/';
export const termsOfService = 'https://perfectbot.ai/legal/terms-of-service/';

export const livechatTourUrl = 'https://www.livechat.com/tour/';
export const livechatDemoVideoUrl = 'https://www.youtube.com/watch?v=-q_KJrnF3e8';
export const stripePanelUrl = process.env.APP_MODE === 'development'
  ? 'https://billing.stripe.com/p/login/test_6oE9DH8wHcKN9uobII'
  : 'https://billing.stripe.com/p/login/cN2cPl1Oh5dL5pKaEE';

export const firstContactMail = 'hello@perfectbot.ai';
export const supportMail = 'support@perfectbot.ai';

export const fallbackLocale = 'en-GB';
