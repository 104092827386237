export function setCookie(name: string, value: string | number, minutes: number): void {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    expires = date.toUTCString();
  }
  document.cookie = `${name}=${value || ''}; expires=${expires}; path=/`;
}

export function getCookie(name: string): string | null {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export function eraseCookie(name: string) {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}
