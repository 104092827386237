import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type PerfectBot from '@/types/PerfectBot';


interface CategoriesContextState {
  categoriesOrder: string[] | null,
  categories: Record<PerfectBot.Category['id'], PerfectBot.Category> | null,
  setCategories: (newCategories: PerfectBot.Category[]) => void,
}

export const CategoriesContext = React.createContext<CategoriesContextState>({
  categoriesOrder: null,
  categories: null,
  setCategories: () => {},
});


export const useCategoriesContext = () => useContext(CategoriesContext);


interface CategoriesContextProviderProps {
  children: React.ReactNode,
}

const CategoriesContextProvider = ({ children }: CategoriesContextProviderProps) => {
  const [state, setState] = useState<Omit<CategoriesContextState, 'setCategories'>>({
    categoriesOrder: null,
    categories: null,
  });

  const setCategories = useCallback((newCategories: PerfectBot.Category[]) => {
    const categoriesMap: Record<string, PerfectBot.Category> = {};

    newCategories.forEach((category) => {
      categoriesMap[category.id] = category;
    });
    setState({
      categories: categoriesMap,
      categoriesOrder: newCategories.map((category) => category.id),
    });
  }, []);

  const data = useMemo(() => ({
    ...state,
    setCategories,
  }), [setCategories, state]);

  return (
    <CategoriesContext.Provider value={data}>
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContextProvider;
