import React, { useMemo, useState, useEffect } from 'react';
import { useInstanceContext } from '@/contexts/InstanceContext';
import type { BotContextState } from './types';


const values: BotContextState = {
  isBotAvailable: false,
  botStatus: 'loading',
  toggleChatWindow: () => null,
  isLoadingBotContext: true,
  sendMessage: () => Promise.resolve(),
};
export const NoBotContext = React.createContext<BotContextState>(values);


interface NoBotContextProviderProps {
  children: React.ReactNode,
}

const NoBotContextProvider = ({ children }: NoBotContextProviderProps) => {
  const { instance } = useInstanceContext();

  const [botStatus, setBotStatus] = useState<BotContextState['botStatus']>('loading');

  const isLoadingBotContext = !instance;

  useEffect(() => {
    if (!isLoadingBotContext) {
      setBotStatus('closed');
    }
  }, [isLoadingBotContext]);


  const data = useMemo(() => ({
    ...values,
    botStatus,
    isLoadingBotContext,
  }), [botStatus, isLoadingBotContext]);

  return (
    <NoBotContext.Provider value={data}>
      {children}
    </NoBotContext.Provider>
  );
};

export default NoBotContextProvider;
