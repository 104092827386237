import { UrlObject } from 'url';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import log from 'loglevel';
import Box from '@mui/material/Box';
import Loader from '@/components/Loader';
import { useOnError } from '@/hooks';
import { PerfectBotCookies, urlStructure } from '@/constants';
import { eraseCookie, getCookie } from '@/utils/cookies';
import type PerfectBot from '@/types/PerfectBot';
import useGetSingleInstance from './useGetSingleInstance';
import { useBestInstanceId, setLastInstanceId } from './helpers';
import InstanceContext from './Context';


interface InstanceContextProviderProps {
  instanceId: string | undefined,
  children: React.ReactNode,
}

const InstanceContextProvider = ({ instanceId: rawInstanceId = '', children }: InstanceContextProviderProps) => {
  const { t } = useTranslation();
  const onError = useOnError();
  const getSingleInstance = useGetSingleInstance();
  const { user } = useAuth0();
  const {
    route,
    query,
    push,
    replace,
  } = useRouter();
  const { isLoading: isLoadingAuth, isAuthenticated } = useAuth0();

  const [instance, setInstance] = useState<PerfectBot.Instance | null>(null);
  const [isLoadingInstanceData, setIsLoadingInstanceData] = useState<boolean>(true);

  const [instanceId, usedSystems] = useBestInstanceId(rawInstanceId);

  const changeInstance = useCallback((newValue: PerfectBot.Instance) => {
    push(urlStructure.appInstance(newValue.id), undefined, { shallow: true })
      .then(() => {
        setLastInstanceId(user?.sub, newValue.id);
        if (newValue?.widget.platform === 'gorgias') {
          window.location.reload();
        }
      })
      .catch((error) => log.warn(error));
    setInstance(newValue);
  }, [push, user?.sub]);

  useEffect(() => {
    // Only on page load
    if (!isLoadingAuth && isAuthenticated && instance === null) {
      const redirectAfterLoginString = getCookie(PerfectBotCookies.REDIRECT_URL);
      const redirectAfterLoginUrl = redirectAfterLoginString
        ? JSON.parse(redirectAfterLoginString) as UrlObject
        : null;
      eraseCookie(PerfectBotCookies.REDIRECT_URL);

      setIsLoadingInstanceData(true);
      getSingleInstance(instanceId)
        .then((newInstance) => {
          if (newInstance) {
            setInstance(newInstance);
            setLastInstanceId(user?.sub, newInstance.id);
            log.debug('ICP getSingleInstance start');

            let newUrl: UrlObject | null = null;
            let shallowRedirect = false;

            // redirectAfterLoginUrl is here for type check
            if (usedSystems.redirectAfterLoginUrl && redirectAfterLoginUrl) {
              log.debug('ICP redirectAfterLoginUrl');
              newUrl = redirectAfterLoginUrl;
            } else if (instanceId === '') {
              log.debug('ICP instanceId === ""');
              newUrl = {
                pathname: urlStructure.appInstance(),
                query: { instanceId: newInstance.id },
              };
            }

            if (usedSystems.currentInsteadOfId) {
              log.debug('ICP currentInsteadOfId');
              shallowRedirect = route !== '/app/[instanceId]';
              newUrl = {
                pathname: newUrl?.pathname ?? route,
                // typeof newUrl?.query === 'object' is here for type check, typeOf null = 'object'
                query: (usedSystems.redirectAfterLoginUrl && typeof newUrl?.query === 'object')
                  ? { ...(newUrl.query ?? {}), instanceId: newInstance.id }
                  : { ...query, instanceId: newInstance.id },
              };
            } else if (usedSystems.intentIdSubstitution) {
              log.debug('ICP intentIdSubstitution');
              newUrl = {
                pathname: urlStructure.appInstance(),
                query: { instanceId: newInstance.id },
              };
            }

            if (newUrl) {
              log.debug(`ICP url replace, shallowRedirect: ${String(shallowRedirect)}`);
              replace(newUrl, undefined, { shallow: shallowRedirect })
                .catch((e) => {
                  log.debug(e);
                });
            }
          }
          setIsLoadingInstanceData(false);
        })
        .catch((error) => {
          setIsLoadingInstanceData(false);
          push({
            pathname: urlStructure.error,
            query: { errorMessage: error.message, logoutButton: 'true' },
          }).catch((e) => log.debug(e));
          onError(error as Error, t('error:errorFetchingInstances'), 'error');
        });
    }
  }, [
    getSingleInstance,
    isAuthenticated,
    isLoadingAuth,
    onError,
    instanceId,
    rawInstanceId,
    instance,
    changeInstance,
    usedSystems.redirectAfterLoginUrl,
    usedSystems.intentIdSubstitution,
    usedSystems.currentInsteadOfId,
    route,
    query,
    user?.sub,
    push,
    replace,
    t,
  ]);

  const data = useMemo(() => ({
    instanceId,
    instance,
    changeInstance,
    isLoadingInstance: isLoadingInstanceData,
  }), [instanceId, instance, changeInstance, isLoadingInstanceData]);

  return (
    <InstanceContext.Provider value={data}>
      {(rawInstanceId !== '' && rawInstanceId !== 'current')
        ? children
        : (
          <Box sx={{ height: '100vh' }}>
            <Loader centered size={60} />
          </Box>
        )}
    </InstanceContext.Provider>
  );
};

export default InstanceContextProvider;
