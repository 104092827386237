import log from 'loglevel';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useIsServerSide } from '@/hooks';
import { urlStructure } from '@/constants';


const UnauthorisedRedirect = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const router = useRouter();
  const isServerSide = useIsServerSide();

  if (
    !isServerSide
    && !isAuthenticated
    && !isLoading
  ) {
    log.debug('Unauthorised redirect');
    void router.push({
      pathname: urlStructure.login,
      query: {
        redirect: JSON.stringify({ pathname: router.pathname, query: router.query }),
      },
    });
  }
  return null;
};


export default UnauthorisedRedirect;
