import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { forOwn } from 'lodash';
import { useBeforeunload } from '@/hooks';
import { customConfirm } from '@/utils/utils';


export default function useDataLostSafetyWarning() {
  const { t } = useTranslation();
  const router = useRouter();

  const doesAnyUnsavedChangesExist = () => {
    let areUnsavedChangesPresent = false;
    forOwn(window.unsavedChangesManager, (itemValue) => {
      if (itemValue) {
        areUnsavedChangesPresent = true;
      }
    });
    return areUnsavedChangesPresent;
  };

  const resetManager = () => {
    (Object.keys(window.unsavedChangesManager ?? {}) as UnsavedChangesManagerValues[])
      .forEach((property) => {
        if (window.unsavedChangesManager) {
          window.unsavedChangesManager[property] = false;
        }
      });
  };

  useBeforeunload((e) => {
    if (doesAnyUnsavedChangesExist()) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    const handler = () => {
      // eslint-disable-next-line no-alert
      if (doesAnyUnsavedChangesExist() && !customConfirm(t('translation:allUnsavedChangesWillBeLost'))) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw 'Route transition canceled';
      }
      resetManager();
    };
    router.events.on('beforeHistoryChange', handler);

    return () => {
      router.events.off('beforeHistoryChange', handler);
    };
  }, [t, router.events]);
}
