import ky from 'ky';


export const getGorgiasRev = async (gorgiasApplicationId: string) => {
  const response = await ky.get(`https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=${gorgiasApplicationId}`);
  const data = await response.text();
  const secondLine = data.split(';')[1];

  const startIndex = secondLine.search('rev=');
  const endIndex = secondLine.search('&applicationId');

  return secondLine.substring(startIndex + 4, endIndex);
};
