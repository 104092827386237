// eslint-disable-next-line max-len
/* eslint-disable @typescript-eslint/restrict-template-expressions,@typescript-eslint/restrict-plus-operands,max-len */
import type { Components } from '@mui/material/styles/components';
import type { Theme } from '@mui/material/styles/createTheme';
// Import of 'themeAugmentation' should start with 'import type {}', its ok that brackets are empty
import type {} from '@mui/lab/themeAugmentation';
import { lighten } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';


export default function componentStyleOverrides(): Components<Theme> {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            backgroundColor: theme.palette.blue[50],
          },
        }),
      },

    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }),
      },
    },
    // LIST ITEM
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.selected,
            },
          },
          '&:hover': {
            backgroundColor: lighten(theme.palette.blue[50], 0.5),
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.light,
          minWidth: 'unset',
          margin: `auto ${theme.spacing(1)} auto 0`,
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& > span': {
            color: theme.palette.text.primary,
            fontWeight: 600,
          },
        }),
      },
    },
    // LIST ITEM END
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          fontWeight: 'bold',
          boxShadow: 'none',
          borderBottomRightRadius: 0,
          textTransform: 'uppercase',
          fontSize: '0.8125rem',

          '&:hover': {
            backgroundColor: theme.palette.common.accent,
            color: theme.palette.text.white,
            boxShadow: 'none',
          },

          ...(ownerState.disabled && {
            color: `${theme.palette.grey[500]} !important`,
          }),

          ...(ownerState.size === 'large' && {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }),
        }),
        contained: ({ theme, ownerState }) => ({
          color: theme.palette.text.white,
          ...(ownerState.disabled && {
            backgroundColor: `${theme.palette.grey[400]} !important`,
            color: `${theme.palette.text.white} !important`,
          }),
        }),
        outlined: {
          borderWidth: '2px',
          paddingTop: '4px',
          paddingBottom: '4px',
          '&:hover': {
            borderWidth: '2px',
            background: 'transparent',
          },
        },
        outlinedPrimary: ({ theme }) => ({
          '&:hover': {
            borderColor: theme.palette.common.accent,
            color: theme.palette.common.accent,
          },
        }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => (
          ownerState.multiline
            ? {
              padding: '13px 16px',
            } : {
              input: {
                padding: '13px 16px',
              },
            }
        ),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.blue[200],
          [`.${outlinedInputClasses.root}.Mui-focused &`]: {
            borderColor: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          transform: 'translate(14px, 12px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
            '&:not(.Mui-error):not(.Mui-disabled)': {
              color: theme.palette.primary.light,
            },
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: ({ theme }) => ({
          color: theme.palette.primary.light,
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textAlign: 'right',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    // CARD
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    // CARD END
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        popper: ({ theme }) => ({
          borderRadius: `${theme.shape.borderRadius}px`,
          boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey[300],
          opacity: 1,
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: ({ theme }) => ({
          borderBottom: '1px solid',
          borderColor: theme.palette.grey[200],
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,41,103,0.7)',
        },
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
          paddingRight: 0,
          paddingLeft: 0,
        }),
      },
    },
    // DIALOG
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '1.25rem',
          paddingTop: 0,
          paddingRight: theme.spacing(7),
          paddingLeft: theme.spacing(7),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingRight: theme.spacing(7),
          paddingLeft: theme.spacing(7),
          width: theme.mixins.dialog.standardWidth,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingRight: theme.spacing(7),
          paddingLeft: theme.spacing(7),
        }),
      },
    },
    // DIALOG END
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          borderColor: theme.palette.blue[50],
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          color: theme.palette.background.paper,
          background: theme.palette.grey[700],
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '38px',
          height: '38px',
          fontSize: '1.2rem',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  };
}
