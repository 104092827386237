import api from '@/api/api';


interface VerifiedIntercomUser {
  hmac: string,
}

export async function verifiedIntercomUser() {
  const response = await api.get('integrations/intercom/hmac');

  const data: VerifiedIntercomUser = await response.json();
  return data.hmac;
}
