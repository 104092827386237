import { useEffect } from 'react';
import log from 'loglevel';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { PerfectBotCookies } from '@/constants';
import type PerfectBot from '@/types/PerfectBot';
import { getCookie } from '@/utils/cookies';
import { getGorgiasRev } from '@/api/gorgias';
import { initChat } from './initChat';


interface GorgiasWidgetProps {
  setBotStatus: (newValue: 'loading' | 'open' | 'closed') => void,
}

const GorgiasWidget = ({
  setBotStatus,
}: GorgiasWidgetProps) => {
  const { instance, isLoadingInstance } = useInstanceContext();

  const { gorgiasApplicationId } = (instance?.widget as PerfectBot.GorgiasWidget);


  useEffect(() => {
    if (!isLoadingInstance) {
      setBotStatus('loading');
      window.GORGIASCHAT_DISABLE_AUTO_OPEN = true;

      getGorgiasRev(gorgiasApplicationId)
        .then((rev) => {
          const gorgiasAppIdOverride = getCookie(PerfectBotCookies.GORGIAS_APP_ID_OVERRIDE);
          initChat(gorgiasAppIdOverride ?? gorgiasApplicationId, rev);
        })
        .then(() => {
          void window.GorgiasChat?.init()
            .then(() => {
              const widget = window.GorgiasChat as GorgiasWidget_.Widget;
              widget.disableSSP();
              widget.disableSegment();

              widget.on('ready', () => {
                const chatMainContainer = document.querySelector('#gorgias-chat-container');
                if (chatMainContainer) {
                  // remove chat button
                  const inviter: HTMLElement | null = chatMainContainer.querySelector('#chat-button');
                  if (inviter) {
                    inviter.style.display = 'none';
                  }
                  // move chat window
                  const chatWindow: HTMLElement | null = chatMainContainer.querySelector('#chat-window');
                  if (chatWindow) {
                    chatWindow.style.right = '22px';
                    chatWindow.style.top = '55px';
                    chatWindow.style.bottom = 'unset';
                    chatWindow.style.marginRight = '0';
                  }
                }
                setBotStatus('closed');
              });
            });
        })
        .catch((error) => {
          log.error(error);
        });

      return () => {
        // Removing Gorgias is incomplete, semi working
        document.querySelector('#gorgias-chat-bundle')?.remove();
        document.querySelector('#gorgias-chat-container')?.remove();

        window.GorgiasChat = undefined;
        window.gorgiasChat = undefined;
        window.gorgiasChatPendingEvents = undefined;
        window.gorgiasChatConfiguration = undefined;
        window.gorgiasChatInterval = undefined;

        document.head.querySelectorAll('style[data-emotion="gorgias-chat-key"]')
          .forEach((item) => {
            item.remove();
          });
        (document.head.querySelectorAll('link[as="script"]'))
          .forEach((item) => {
            if ((item as HTMLLinkElement).href.startsWith('https://assets.gorgias.chat')) {
              item.remove();
            }
          });

        window.sessionStorage.removeItem('gorgias.renderedOnce');
        window.sessionStorage.removeItem('gorgias.started-visit');

        window.localStorage.removeItem('gorgias.version');
        window.localStorage.removeItem('gorgias.chat-access-token');
        window.localStorage.removeItem('gorgias.chat-last-seen');
        window.localStorage.removeItem('gorgias.seen-campaigns-ids');
        window.localStorage.removeItem('gorgias.campaign-ab-group');
        window.localStorage.removeItem('gorgias.last-sent-amplitude-event');
        window.localStorage.removeItem('gorgias.email-captured');
        window.localStorage.removeItem('gorgias.chat-last-pending-message');
        window.localStorage.removeItem('gorgias.ssp');
        window.localStorage.removeItem('gorgias.chat-last-opened-date');
        window.localStorage.removeItem('gorgias.chat-ws-last-connected');
        window.localStorage.removeItem('gorgias.last-wait-time');
        window.localStorage.removeItem('gorgias.tabs-opened');
        window.localStorage.removeItem('gorgias.visit-count');
      };
    }
    return () => null;
  }, [
    gorgiasApplicationId,
    isLoadingInstance,
    setBotStatus,
  ]);

  return null;
};


export default GorgiasWidget;
