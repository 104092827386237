export function initChat(win, doc, c, scriptId) {
  function i(n) {
    return chatObject._h ? chatObject._h.apply(null, n) : chatObject._q.push(n);
  }

  const chatObject = {
    _q: [],
    _h: null,
    _v: '2.0',
    on: function () {
      i(['on', c.call(arguments)]);
    },
    once: function () {
      i(['once', c.call(arguments)]);
    },
    off: function () {
      i(['off', c.call(arguments)]);
    },
    get: function () {
      if (!chatObject._h) {throw new Error('[LiveChatWidget] You can\'t use getters before load.')}
      return i(['get', c.call(arguments)]);
    },
    call: function () {
      i(['call', c.call(arguments)]);
    },
    init: function () {
      const n = doc.createElement('script');
      n.async = !0;
      n.type = 'text/javascript';
      n.id = scriptId;
      n.src = 'https://cdn.livechatinc.com/tracking.js';
      doc.head.appendChild(n);
    },
  };
  !win.__lc.asyncInit && chatObject.init();
  win.LiveChatWidget = win.LiveChatWidget || chatObject;
  return chatObject;
}
