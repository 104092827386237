import React from 'react';
// import React, { ErrorInfo } from 'react';
import { supportMail } from '@/constants';


interface ErrorBoundaryProps {
  children: React.ReactNode,
}

interface ErrorBoundaryState {
  hasError: boolean,
  errorMessage: string,
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }


  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, errorMessage: error.message };
  }

  // componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  //   // You can use your own error logging service here
  //   console.log({ error, errorInfo });
  // }
  componentDidCatch() {

  }

  render() {
    const { children } = this.props;
    const { hasError, errorMessage } = this.state;

    // Check if the error is thrown
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div>
            <div
              style={{
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'grid',
                  placeItems: 'center',
                  backgroundColor: '#e8f0f8',
                  zIndex: 2147483641,
                }}
              >
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#002967',
                    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    borderRadius: '5px',
                    padding: '20px',
                    maxWidth: '60vh',
                    fontFamily: '"Mulish", sans-serif',
                  }}
                >
                  <h2>
                    Ooops, we have an error!
                  </h2>
                  <pre>
                    <code
                      style={{
                        overflow: 'auto',
                        maxWidth: '100%',
                        display: 'block',
                        padding: '4px 4px 10px',
                      }}
                    >
                      {errorMessage}
                    </code>
                  </pre>
                  <div>
                    Need help?&nbsp;
                    <a
                      href={`mailto:${supportMail}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
