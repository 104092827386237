import { useCallback } from 'react';
import { HTTPError } from 'ky';
import { useQueryClient, QueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type PerfectBot from '@/types/PerfectBot';
import { getInstance } from '@/api/instances';
import { getInstances } from '@/api/users';


const getSingleBestInstance = async (queryClient: QueryClient, userId: string | undefined) => {
  const instances = await queryClient.fetchQuery(
    ['instances', userId],
    () => getInstances(userId),
    { staleTime: 5000 }
  );
  if (Array.isArray(instances) && instances.length > 0) {
    const defaultInstances = instances.filter((inst) => inst.default);
    if (defaultInstances.length > 0) {
      return defaultInstances[0];
    }
    return instances[0];
  }
  return null;
};

const useGetSingleInstance = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth0();

  return useCallback(async (
    instanceId: string,
  ): Promise<PerfectBot.Instance> => {
    let instance: PerfectBot.Instance;
    if (instanceId !== '') {
      try {
        instance = await queryClient.fetchQuery(
          ['instance', instanceId, user?.sub],
          () => getInstance(instanceId)
        );
      } catch (error) {
        if ((error as HTTPError)?.response?.status === 403) {
          throw new Error('e003');
        }
        throw error;
      }
    } else {
      const instanceOrNull = await getSingleBestInstance(queryClient, user?.sub);
      if (instanceOrNull === null) {
        throw new Error('e002');
      }
      instance = instanceOrNull;
    }

    return instance;
  }, [queryClient, user?.sub]);
};

export default useGetSingleInstance;
