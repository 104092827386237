import { useCallback } from 'react';
import { useSnackbar, OptionsObject as NotiOptionsObject, VariantType } from 'notistack';
import log from 'loglevel';


type OptionsObject = Omit<NotiOptionsObject, 'variant'>;

export default function useOnError() {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback((
    error: Error,
    message: string,
    variant: VariantType,
    snackbarOptions?: OptionsObject
  ) => {
    log.error(error);
    enqueueSnackbar(message, { variant, ...snackbarOptions });
  }, [enqueueSnackbar]);
}
