import { useMemo } from 'react';
import { useInstanceContext } from '@/contexts/InstanceContext';
import type PerfectBot from '@/types/PerfectBot';


export default function useInstanceType(): PerfectBot.InstanceType | null {
  const { instance } = useInstanceContext();
  return useMemo(() => {
    if (typeof instance?.instanceTypeId === 'string') {
      return instance.instanceTypeId;
    }
    return null;
  }, [instance]);
}
