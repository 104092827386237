type WaitAndGet = <T extends unknown>(
  get: () => Promise<T>,
  watchedVariable: unknown
) => Promise<T>;

export const waitAndGet: WaitAndGet = (get, watchedVariable) => new Promise((resolve) => {
  if (watchedVariable) {
    resolve(get());
  }
});
