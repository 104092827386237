import { useAuth0 } from '@auth0/auth0-react';
import { getCookie, setCookie } from '@/utils/cookies';
import { PerfectBotCookies } from '@/constants';


export const setLastInstanceId = (userId: string | undefined, instanceId: string) => {
  if (!userId || userId === '') {
    return;
  }
  const userIdInstanceIdMap: Record<string, string> = JSON.parse(
    getCookie(PerfectBotCookies.LAST_INSTANCE) ?? '{}'
  );
  userIdInstanceIdMap[userId] = instanceId;
  // 10_080 = 7 dni
  setCookie(PerfectBotCookies.LAST_INSTANCE, JSON.stringify(userIdInstanceIdMap), 10_080);
};

const getLastInstanceId = (userId: string | undefined) => {
  const cookie = getCookie(PerfectBotCookies.LAST_INSTANCE);
  if (!userId || userId === '' || !cookie) {
    return undefined;
  }
  const userIdInstanceIdMap: Record<string, string> = JSON.parse(cookie);
  return userIdInstanceIdMap[userId];
};

export const useBestInstanceId = (rawInstanceId: string): [string, {
  redirectAfterLoginUrl: boolean,
  intentIdSubstitution: boolean,
  currentInsteadOfId: boolean,
}] => {
  const { user } = useAuth0();

  let instanceId = rawInstanceId;
  const usedSystems = {
    redirectAfterLoginUrl: false,
    intentIdSubstitution: false,
    currentInsteadOfId: false,
  };

  if (typeof document === 'undefined') {
    return [instanceId, usedSystems];
  }

  const redirectAfterLoginUrl = getCookie(PerfectBotCookies.REDIRECT_URL);
  if (redirectAfterLoginUrl) {
    usedSystems.redirectAfterLoginUrl = true;
    const redirect = JSON.parse(redirectAfterLoginUrl);
    instanceId = redirect.query.instanceId;
  }

  const lastInstanceId = getLastInstanceId(user?.sub) ?? '';

  if ((instanceId === '' || instanceId === 'current') && lastInstanceId !== '') {
    usedSystems.intentIdSubstitution = true;
    if (instanceId === 'current') {
      usedSystems.currentInsteadOfId = true;
    }
    instanceId = lastInstanceId;
  }

  return [instanceId, usedSystems];
};
