export function initChat(applicationId, rev) {
  var r = applicationId, i = document.createElement('script');
  i.src = `https://config.gorgias.chat/gorgias-chat-bundle.js?rev=${rev}&applicationId=${r}`, i.id = 'gorgias-chat-bundle', i.setAttribute('data-ot-ignore', '');
  var s = {
    get(o, t) {
      if (t != 'init' && t != 'resolve') throw new Error('You are trying to use the Gorgias Chat API before its initialization (property or function "' + t + '")! Please use `GorgiasChat.init()`. Refer to our documentation https://docs.gorgias.com/gorgias-chat/advanced-customization-new-chat for more info.');
      let a = o[t];
      return typeof a == 'function' ? a.bind(o) : a;
    }
  };
  window.GorgiasChat = new Proxy({}, s);
  var e = new Promise(function (o, t) {
    window.GorgiasChat.resolve = o;
  });
  window.GorgiasChat.init = function () {
    return e;
  }, window.gorgiasChatPendingEvents = [];

  function n() {
    document.body.appendChild(i);
  }

  document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', n) : n();
  var g = {
    get(o, t) {
      if (t != 'init') throw new Error('You are using the legacy `gorgiasChat` Chat API! Please use `GorgiasChat` instead. Refer to our documentation https://docs.gorgias.com/gorgias-chat/advanced-customization-new-chat for more info.');
      let a = o[t];
      return typeof a == 'function' ? a.bind(o) : a;
    }
  };
  window.gorgiasChat = new Proxy({}, g), window.gorgiasChat.init = function () {
    return console.warn('You are using the legacy `gorgiasChat.init()` function, please use the new `GorgiasChat` chat API (`GorgiasChat.init()`). Refer to our documentation https://docs.gorgias.com/gorgias-chat/advanced-customization-new-chat for more info.'), e;
  };
}
