import ky from 'ky';
import {
  PopupConfigOptions,
  GetTokenWithPopupOptions,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-react';
import { apiUrl } from '@/constants';


type GetAccessTokenWithPopup = (
  options?: (GetTokenWithPopupOptions | undefined),
  config?: (PopupConfigOptions | undefined)
) => Promise<string>;
type GetAccessTokenSilently = (options?: (GetTokenSilentlyOptions | undefined)) => Promise<string>;
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let getAccessTokenWithPopup: GetAccessTokenWithPopup;
// @ts-ignore
let getAccessTokenSilently: GetAccessTokenWithPopup;

export const setGetTokenWithPopup = (fn: GetAccessTokenWithPopup) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAccessTokenWithPopup = fn;
};

export const setGetTokenSilently = (fn: GetAccessTokenSilently) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAccessTokenSilently = fn;
};

const api = ky.create({
  prefixUrl: apiUrl,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await getAccessTokenSilently();
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
  },
});


export default api;
